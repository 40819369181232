@import '../../variables.scss';

.menu {
    width: 90%;
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    padding: 40px 0;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;

    &__items {
        display: flex;
    }

    &__item {
        margin: 0 32px;
        text-decoration: none;
        color: $dark;
        transition: $transition;

        &:hover {
            color: $purple;
        }
    }

    &__btn {
        border: 1px solid $purple;
        padding: 8px 16px;
        transition: $transition;
        color: $purple;
        border-radius: 20px;
        cursor: pointer;
        text-decoration: none;

        &:hover {
            background: rgba($color: #fff, $alpha: .4);
        }
    }
}


@media (max-width: 960px) {
 .menu__item {
    margin: 0 20px;
 } 
}


@media (max-width: 767px) {
    .menu__items {
        display: none;
     } 
  }