@import './variables.scss';

body {
  color: $dark;
  font-family: 'Gilroy';
}

.gradientBg {
  background-image: linear-gradient(to right bottom, #fef9fc, #f9f3f9, #f3eef7, #ebe9f6, #e1e4f4); 
}

.ml-5 {
  margin-left: 20px;
}

.container {
  width: 90%;
  max-width: 1140px;
  margin: 0 auto;
  color: $dark;
  position: relative;
}

.title {
  font-size: 44px;
  font-weight: 500;
  letter-spacing: 1px;
}

.subtitle {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 6px;
}

.text {
  font-size: 14px;
  line-height: 22px;
}

.grayText {
  color: $gray;
}

.banner {
  position: relative;

  &__bgImgWrapper {
    width: 100%;
    height: 700px;
    z-index: -1;
    overflow: hidden;
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      }
  }

  &__slogan {
    font-size: 100px;
    color: $dark;
    font-weight: 300;
    letter-spacing: 2px;
    margin-top: 180px;
    line-height: 90px;
    position: absolute;
    top: 0;
    z-index: 2;
    width: 100%;
  }

  &__slogan--accent {
    position: relative;

    svg {
      position: absolute;
      width: 110%;
      bottom: -12px;
      left: -15px;
    }
  }

  &__slogan--left {
    text-align: left;
    animation: fadeInLeft 1s;
  }

  &__slogan--right {
    text-align: right;
    animation: fadeInRight 1s;
  }

  &__imagesSectionWrapper {
    position: absolute;
    top: 420px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 1300px;
  }
  
  &__imagesWrapper {
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    // overflow: hidden;
  }

  &__imgWrapper {
    margin: 0 10px;
  }

  &__imgWrapper:first-child {
    margin-right: 5px;
  }

  &__imgWrapper:nth-child(2) {
    margin-right: 30px;
  }

  &__imgWrapper:nth-child(3) {
    margin-right: 20px;
  }

  &__mainBtn {
    font-size: 28px;
    color: $purple;
    display: flex;
    width: fit-content;
    margin: 0 auto;
    margin-top: 50px;
    text-decoration: none;
    position: relative;
    padding: 30px;
    border: 1px solid #d0d5ef;
    border-radius: 100px;
    transition: .2s ease-in-out;
    width: 260px;

    &--arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      transition: .3s ease-in-out;
      background: rgb(255,255,255);
      background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9822303921568627) 34%, rgba(254,254,254,1) 73%, rgba(246,246,246,0) 100%);
      // z-index: -2;
    }

    &--blurPurpleCircle {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -70px;
      z-index: -1;
      width: 150px;
      transition: .4s ease-in-out;
    }

    &--blurYellowCircle {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -140px;
      z-index: -1;
      width: 150px;
      transition: .4s ease-in-out;
      opacity: 1;
    }

    &:hover {
      border: 1px solid #c0c7e6;

      .banner__mainBtn--arrow {
        right: -10px;
      }

      .banner__mainBtn--blurPurpleCircle {
        left: -20px;
      }

      .banner__mainBtn--blurYellowCircle {
        left: -50px;
      }
    }
  }
}


// ABOUT SECTION

.about {
  margin-top: 300px;
  margin-bottom: 150px;
  height: 390px;
  background-image: linear-gradient(to right bottom, #fef9fc, #f9f3f9, #f3eef7, #ebe9f6, #e1e4f4); 

  .title {
    text-align: center;
  }

  .container {
    padding-top: 90px;
  }

  &__items {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__item {
    width: 250px;
    height: 300px;
    background: #fff;
    overflow: hidden;
    margin: 0 10px 20px 10px;
    box-shadow: 2px 6px 16px rgba(48, 45, 45, 0.08);
    border-radius: 16px;
  }

  &__content {
    padding: 20px;
  }
}

// CREATE COLLECTION SECTION

.createCollection {
  padding: 150px 0;
  overflow: hidden;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__info {
    flex: 1;
    min-width: 300px;
    margin-right: 10%;
    margin: 30px 10% 30px 0;
  }

  &__imgWrapper {
    position: relative;
    flex: 1;

    img {
      width: 100%;
    }

    .purpleShadow {
      position: absolute;
      top: -200px;
      left: -200px;
      z-index: -1;
    }

    .yellowShadow {
      position: absolute;
      bottom: -200px;
      right: -200px;
      z-index: -10;
    }
  }
}

// LEARN WORDS

.learnWords {
  padding: 40px 0;
  
  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__info {
    flex: 1;
    min-width: 300px;
    margin: 30px 0 30px 10%;
  }

  &__description {
    margin-top: 20px;
    font-size: 14px;
    line-height: 22px;
    width: 450px;
    max-width: 100%;
  }

 &__gradienImgWrapper {
    overflow: hidden;
    background: linear-gradient(176.27deg, #F7F1EA -4.5%, #E2E5F4 96.94%);
    border-radius: 140px;
    width: 35%;
    height: 420px;
    position: relative;
    padding: 0 20px !important;
    
    img {
    box-sizing: border-box;
      position: absolute;
      top: 120px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

// MOBILE APP SECTION

.mobileApp {
  padding: 100px 0;
  overflow: hidden;
  
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__info {
    flex: 1;
    min-width: 300px;
    margin: 30px 10% 100px 0;
  }

  &__title--accent {
    position: relative;

    svg {
      position: absolute;
      width: 110%;
      bottom: -12px;
      left: -10px;
    }
  }

  &__description {
    margin-top: 20px;
    font-size: 14px;
    line-height: 22px;
  }

  &__gradienImgWrapper--large {
    background: linear-gradient(176.27deg, #F7F1EA -4.5%, #E2E5F4 96.94%);
    border-radius: 140px;
    width: 580px;
    height: 520px;
    position: relative;

    img {
      width: 130%;
      margin-top: -70px;
      margin-left: -80px;
    }
  }
}


// LEARN WORD SECTION

.learnWord {
  margin: 100px 0;
  display: flex;
  align-items: center;
  border-radius: 40px;
  flex-wrap: wrap;
  justify-content: center;
  background-image: linear-gradient(to right bottom, #fef9fc, #f9f3f9, #f3eef7, #ebe9f6, #e1e4f4); 

  &__imagesWrapper {
    height: 390px;
    width: 550px;
    position: relative;
    padding: 0 20px;
    display: flex;
    align-items: flex-end;
    overflow: hidden;
  }
    
    &__imgWrapper {
      margin: 0 7px;
      margin-bottom: -100px;

      img {
          width: 100%;
      }
  }

  &__info {
    padding: 50px;
    flex: 1;
  }
}

// EXTENSION SECTION

.extension {
  padding: 170px 0;
  overflow: hidden;

  .title {
    max-width: 600px;
    width: 100%;
    text-align: center;
    margin:  0 auto;
  }

  &__imagesWrapper {
    margin-top: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
  }
  
  &__imgWrapper {
    width: 100%;
    margin: 30px 10px 0 10px;

    img {
      width: 100%;
    }
  }

  .purpleShadow {
    position: absolute;
    bottom: -150px;
    left: -150px;
    z-index: -1;
  }

  .yellowShadow {
    position: absolute;
    top: -150px;
    right: -150px;
    z-index: -10;
  }
}

// CONTACT US SECTION
.contact {

  &__title {
    text-align: center;
  }

  &__form {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    margin-top: 50px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.042) 0px 0px 0px 1px;
    padding: 50px;
    border-radius: 18px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.186);
    backdrop-filter: blur(2px);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 17px;
    line-height: 25px;
    animation: fadeInOut 4s;
    opacity: 0;
  }
  
  &__submitBtn {
    padding: 14px 20px;
    border-radius: 18px;
    font-size: 16px;
    color: $purple;
    font-weight: 400;
    background: #f7f7faae;
    border: 1px solid #e3e3f2;
    cursor: pointer;
    transition: .2s ease-in-out;
    margin-top: 20px;
    font-family: 'Gilroy';
    display: flex;
    align-items: center;
    
    &:hover {
      border: 1px solid transparent;
      background: #f7f7fa;
    }
  }
  
  &__inputWrapper {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    margin-top: 10px;
  }

  &__label {
    transition: .3s ease-in-out;
    touch-action: manipulation;
    transform: translate(15px, 7px) scale(1);
    color: #a6a8b0;
    background: #ffffff;
    border-radius: 5px;
    width: fit-content;
    padding: 0 5px;
    pointer-events: none;
    font-weight: 400;
    font-size: 14px;
  }

  &__input {
    transition: .2s ease-in-out;
    touch-action: manipulation;
    width: 100%;
    border: 1px solid #E5E6E8;
    border-radius: 18px;
    box-sizing: border-box;
    padding: 14px 18px;
    margin-bottom: 12px;
    font-size: 16px;
    outline: none;
    font-family: 'Gilroy';
  }

  &__input--error {
    @extend .contact__input;
    border: 1px solid rgb(243, 148, 148);
    box-shadow: rgba(217, 79, 79, 0.3) 0px 1px 4px;
  }

  &__textarea {
    width: 100%;
    transition: .2s ease-in-out;
    resize: none;
    border: 1px solid #E5E6E8;
    border-radius: 18px;
    outline: none;
    padding: 14px 18px;
    font-family: 'Gilroy';
    font-size: 16px;   
    box-sizing: border-box;
    margin-top: 10px;

    &::placeholder {
      color: #a6a8b0;
    }
  }

  &__textarea--error {
    @extend .contact__textarea;
    border: 1px solid rgb(243, 148, 148);
    box-shadow: rgba(217, 79, 79, 0.3) 0px 1px 4px;
  }

  &__input::placeholder {
    color: #ffffff;
  }

  &__input:placeholder-shown + &__label {
    transform: translate(15px, 35px) scale(1);
    font-size: 16px;
    text-transform: inherit;
  }

  &__input:focus + &__label {
    transform: translate(15px, 7px) scale(1);
    font-size: 14px;
  } 

  &__input:focus {
    border: 1px solid #6a75ca6e;
  }

  &__loader {
    flex-direction: column;
    justify-content: center;
    width: fit-content;
    margin-left: 10px;
  }
  
  &__loaderCircle {
     font-size: 10px;
     margin: 0 auto;
     text-indent: -9999em;
     width: 18px;
     height: 18px;
     border-radius: 50%;
     background: #6a75ca;
     background: -moz-linear-gradient(left, #6a75ca 10%, #f7f7faae 42%);
     background: -webkit-linear-gradient(left, #6a75ca 10%, #f7f7faae 42%);
     background: -o-linear-gradient(left, #6a75ca 10%, #f7f7faae 42%);
     background: -ms-linear-gradient(left, #6a75ca 10%, #f7f7faae 42%);
     background: linear-gradient(to right, #6a75ca 10%, #f7f7faae 42%);
     position: relative;
     -webkit-animation: load3 1.4s infinite linear;
     animation: load3 1.4s infinite linear;
     -webkit-transform: translateZ(0);
     -ms-transform: translateZ(0);
     transform: translateZ(0);
   }

   &__loaderCircle:before {
     width: 50%;
     height: 50%;
     background: #6a75ca;
     border-radius: 100% 0 0 0;
     position: absolute;
     top: 0;
     left: 0;
     content: '';
   }

    &__loaderCircle:after {
     background: #f7f7fa;
     width: 75%;
     height: 75%;
     border-radius: 50%;
     content: '';
     margin: auto;
     position: absolute;
     top: 0;
     left: 0;
     bottom: 0;
     right: 0;
   }

   @-webkit-keyframes load3 {
     0% {
       -webkit-transform: rotate(0deg);
       transform: rotate(0deg);
     }
     100% {
       -webkit-transform: rotate(360deg);
       transform: rotate(360deg);
     }
   }

   @keyframes load3 {
     0% {
       -webkit-transform: rotate(0deg);
       transform: rotate(0deg);
     }
     100% {
       -webkit-transform: rotate(360deg);
       transform: rotate(360deg);
     }
   }
}

// DOWNLOAD APP SECTION

.download {
  border-radius: 40px;
  margin: 100px 0;
  padding: 75px 10px;
  background-image: linear-gradient(to right bottom, #fef9fc, #f9f3f9, #f3eef7, #ebe9f6, #e1e4f4); 
  position: relative;

  &__imgWrapper {
    margin:  0 auto;
    width: fit-content;
  }

  &__title {
    font-size: 33px;
    font-weight: 500;
    text-align: center;
    margin-top: 20px;
  }

  .text {
    text-align: center;
    margin-top: 20px;
  }

  &__buttons {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;

    img {
      margin: 0 10px;
    }
  }

  &__buttons--disabled {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: fit-content;

    a {
      opacity: .2;
      pointer-events: none;
    }
  }

  &__buttonsLabel {
    position: absolute;
    left: -30px;
    transform: translateX(2em);
    top: -20px;
    background: #fff;
    color: $purple;
    opacity: 1;
    border-radius: 10px;
    padding: 5px 7px;
    font-weight: 500;
    -moz-transform: rotate(-10deg);
    -webkit-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    transform: rotate(-10deg);
    border: 1px dotted $purple;
  }
}


@media (max-width: 1200px) {
 .about {
    background-image: linear-gradient(to bottom, #fef9fc, #f9f3f9, #f3eef7, #f1effb, #ffffff); 
    height: 100%;
    padding-bottom: 20px;
    
    .container {
      padding-top: 70px;
    }

    &__items {
      justify-content: center;
    }
  }  

}

@media (max-width: 920px) {

  .banner {

    &__bgImgWrapper {
      height: 600px;
    }

    &__slogan {
      font-size: 60px;
      line-height: 70px;
      margin-top: 150px;
    }

    &__imagesSectionWrapper {
      top: 400px;
    }

    &__imgWrapper {
      display: flex;
      justify-content: flex-end;
      margin: 0 5px;

      img {
        width: 130%;
      }
    }

    &__imgWrapper:first-child {
      margin-right: 5px;
    }
  
    &__imgWrapper:nth-child(2) {
      margin-right: 20px;
    }
  
    &__imgWrapper:nth-child(3) {
      margin-right: 10px;
    }

    &__mainBtn {
      padding: 20px;
      font-size: 26px;
    }
  }

  .learnWord {
    margin: 20px 0;
    flex-direction: column-reverse;
    background-image: linear-gradient(to right bottom, #fef9fc, #fdf8fc, #fbf8fc, #faf7fc, #f8f7fc, #f6f6fb, #f5f5fb, #f3f4fa, #f1f2f9, #eff0f9, #edeef8, #ebecf7);

    &__imagesWrapper {
      box-sizing: border-box;
      width: 100%;
      justify-content: center;
      flex-wrap: wrap;
      overflow: visible;
      height: 100%;
    }

    &__imgWrapper {
      margin-bottom: 20px;

      img {
        width: 250px;
      }
    }

    &__info {
      text-align: center;
    }
  }

  .extension {
    padding: 120px 0;

    .title {
      text-align: start;
    }
    
    &__imagesWrapper {
      flex-wrap: wrap;
      margin-top: 10px;
    }

    &__imgWrapper {
      margin: 20px 0 0 0;
    }

    .purpleShadow {
      top: -250px !important;
      left: -200px;
    }
  
    .yellowShadow {
      bottom: -150px !important;
      top: auto;
      right: -200px;
      // top: 200px;
    }
  }

  .download {
    &__extensionBtn {
      display: none;

    }
  }
}


@media (max-width: 768px) {
  .title {
    font-size: 32px;
    font-weight: 400;
  }


  .createCollection {
    padding: 50px 0;
  
    &__content {
      justify-content: center;
      flex-direction: column;
    }

    .purpleShadow {
      position: absolute;
      top: -150px;
      left: -100px;
      z-index: -1;
    }

    .yellowShadow {
      position: absolute;
      bottom: -100px;
      right: -100px;
      z-index: -10;
    }
  }


  .learnWords {

    &__content {
      justify-content: center;
      // flex-direction: column-reverse;
    }

    &__info {
      width: 100%;
      margin: 50px 0 20px 0;
    }

    &__description {
      width: 100%;
    }

    &__gradienImgWrapper {
        width: 100%;
        height: 330px;
        border-radius: 100px;
        box-sizing: border-box;

        img {
          top: 60px;
        }
    }
  }

  .mobileApp {
    padding: 50px 0;

    &__content {
      justify-content: center;
      flex-direction: column-reverse;
    }

    &__info {
      width: 100%;
      margin: 50px 0;
    }

    &__description {
      width: 100%;
    }

    &__gradienImgWrapper--large {
        width: 300px;
        height: 310px;
        border-radius: 80px;

        img {
            width: 130%;
            margin-top: -30px;
            margin-left: -40px;
        }
    }
  }

  .learnWord {
    &__info {
      padding: 40px 10px;
    }
  }

  .extension {
    padding: 70px 0;
  }

  .download {
    margin: 50px 0;
    padding: 50px 10px;
    background-image: linear-gradient(to right bottom, #fef9fc, #fdf8fc, #fbf8fc, #faf7fc, #f8f7fc, #f6f6fb, #f5f5fb, #f3f4fa, #f1f2f9, #eff0f9, #edeef8, #ebecf7);

    &__title {
      font-size: 30px;
      font-weight: 400;
    }
  }
// 
  .contact {
    &__form {
      padding: 20px;
    }
  }

}

@media (max-width: 500px) {

  .banner {

    &__imagesSectionWrapper {
      top: 470px;
    }

    &__imgWrapper {
      img {
        width: 140%;
      }
    }

    &__mainBtn {
      padding: 15px;
      font-size: 22px;
    }
  }

  .about {
    margin: 200px 0 20px 0;
  }

  .contact {
    &__submitBtn {
      padding: 14px 20px;
      width: 100%;
    }
  }

  .download {
    &__buttons--disabled {
      width: 200px;
    }
  }
}


@keyframes fadeInOut {
  0% { opacity: 0; }
  20% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes fadeInLeft {
  0% { margin-left: -50px; opacity: 0; }
  100% { margin-left: 0; opacity: 1; }
}

@keyframes fadeInRight {
  0% { margin-right: -50px; opacity: 0; }
  100% { margin-right: 0; opacity: 1; }
}